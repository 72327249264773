@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.Heropage {
  background: url(../../../public/New.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 500px) {
  .Heropage {
    background: url(../../../public/mobile_view.png);
    background-position: center;
  }
}
